<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t('customer.grouptitle') }}</h3>
      </v-col>
      <v-col class="text-right">
        <v-btn outlined small color="primary" @click="link('customer')">{{ $t('customer.title') }}</v-btn>
      </v-col>
    </v-row>
    <br />
    <v-divider></v-divider> <br />
    <v-btn small color="success" @click="editGroup({})"> <v-icon dark>fa-plus</v-icon> {{ $t('all.add') }} </v-btn>

    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">{{ $t('customer.groupname') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tableData" :key="item.login" @click="editGroup(item)">
          <td>{{ item.name }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import edit from '../../components/edit.vue'
import { create } from 'vue-modal-dialogs'
const messageBoxE = create(edit)

export default {
  data() {
    return {
      tableLoad: false,
      tableData: ['load'],
      modal: '',
      modal_delete: '',
      modalTitle: '',
      modalView: '',
    }
  },
  components: {},
  mounted: function() {
    this.dataLoad()
  },
  methods: {
    modalClose: function(prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },

    dataLoad: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'customer_group').then(
        response => {
          if (response.body.err > 0) {
            t.tableData = ['error']
          } else {
            t.tableData = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    editGroup(group) {
      var t = this
      messageBoxE({
        urlSave: 'customer_group/save/',
        urlDel: 'customer_group/delete/',
        id: group.id,
        fields: [
          {
            type: 'input',
            text: t.$t('customer.groupname'),
            name: 'name',
            value: group.name,
          },
        ],
        title: t.$t('customer.groupedittitle'),
        titleDel: t.$t('customer.groupdeltitle'),
        delMsg: t.$t('customer.groupdelconfirm', [group.name]),
      })
        .transition()
        .then(response => {
          if (response) {
            t.dataLoad()
          }
          console.log('response', response)
        })
    },
    link: function(view) {
      this.$router.push(view)
    },
  },
}
</script>

<style></style>
